<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.0004 1H16.5688L14.319 12.3876C14.1339 13.3247 13.312 14 12.3569 14H4.50498C3.61393 14 2.83036 13.4105 2.58336 12.5544L0.868486 6.61054C0.68402 5.97116 1.16384 5.33333 1.8293 5.33333H15.6825"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="5.7143"
    />
    <path
      d="M3.05273 18.5C3.05273 19.4 3.65273 20 4.55273 20C5.45273 20 6.05273 19.4 6.05273 18.5C6.05273 17.6 5.45273 17 4.55273 17C3.65273 17 3.05273 17.6 3.05273 18.5Z"
      fill="currentColor"
    />
    <path
      d="M10.0527 18.5C10.0527 19.4 10.6527 20 11.5527 20C12.4527 20 13.0527 19.4 13.0527 18.5C13.0527 17.6 12.4527 17 11.5527 17C10.6527 17 10.0527 17.6 10.0527 18.5Z"
      fill="currentColor"
    />
  </svg>
</template>
